
@media all and (max-width: 1530px){
.page_main{
/*	height: auto;*/
}
.edit_resume {
	max-width: 400px;
}
.text_paper {
	max-width: 440px;
	overflow: hidden;
}
.header {
	max-width: 850px;
}
.text_paper_content p {
	font-size: 10px;
	line-height: 16.5px;
	font-family: 'Baloo';
	color: #525151;
	max-width: 321px;
}
.text_paper_contents_all {
	position: absolute;
	top: 18px;
	left: 33px;
}
.text_paper_title h3 {
	margin-bottom: -2px;
	font-size: 21px;
	line-height: 35px;
}
.btn_syle a {
	font-size: 23px;
	line-height: 27px;
}
.btn_syle {
	right: 11px;
	bottom: 18px;
}
.text_paper_items ul li a img {
	height: 35px;
}
.text_paper_items ul li a span {
	font-size: 14px;
	line-height: 24px;
}
.tpibak.text_paper_items_back2 {
	top: 40px;
}
.text_paper_items_back1 {
	top: -44px;
	left: -49px;
}
.text_paper_items_all {
	padding-right: 8px;
}
.text_paper_items {
	padding: 12px 0px 0px 14px;
}
.header_right img {
	max-width: 450px;
}
.header_right_btn a {
	font-size: 29px;
	line-height: 40px;
}
.header_right_btn {
	margin-top: -18px;
	padding-right: 11px;
}
.header_right img {
	max-width: 585px;
}
.text_paper_items_icon ul li a img {
	width: 16px;
	height: 16px;
	margin-bottom: 4px;
}
.text_paper_items_icon {
	position: absolute;
	top: 7px;
	left: -25px;
}
.header_right_btn {
	margin-top: -22px;
	padding-right: 11px;
}




}


@media all and (max-width: 1199px){

}

@media all and (max-width: 991px){
	
}

@media all and (max-width: 767px){
.header {
	max-width: 850px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}





}



@media all and (max-width: 575px){
	
}

@media all and (max-width: 450px){
.header_right {
	margin-top: 30px;
}
.text_paper_content p {
	font-size: 9px;
	line-height: 17.6px;
}
.header_right_btn {
	margin-top: -17px;
	padding-right: 9px;
}



}